import React, { useEffect, useState } from "react";
import { BackTop } from "antd";
import { Link } from "react-router-dom";
import { url } from "../../constants/navhome";

const headeritemleft = [
  {
    headname: "Room",
    headlink: url.Room,
  },
  {
    headname: "Services",
    headlink: url.Services,
  },
];
const headeritemright = [
  {
    headname: "About Us",
    headlink: url.Aboutus,
  },
  {
    headname: "Gallery",
    headlink: url.Gallery,
  },
];

const Header = () => {
  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const header = document.querySelector(".navbarshadow");
    const scrollTop = window.scrollY;
    if (scrollTop >= 300) header.classList.add("fixed-nav");
    else header.classList.remove("fixed-nav");
  };
  /* end*/
  const [isActive, setIsActive] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const widthEvent = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, []);
  return (
    <>
      <BackTop />
      <div className="sidebar_contact">
        <div class="toggle">
          {/* <img
            loading="lazy"
            src="./resources/images/Phone.svg"
            class="call-image"
            alt="phone-icon"
          /> */}
          <p>
            <Link to={url.Inquiry} className="has-text-white">
              Book Now
            </Link>
          </p>
        </div>
      </div>
      <nav
        className="navbar navbarshadow"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="container navigation">
          <div className="navbar-brand">
            {width < 992 && (
              <>
                <div style={{ width: "100%" }}></div>
                <a className="navbar-item logo" href="\">
                  <img src="main_logo.png" alt="logo" />
                </a>
              </>
            )}
            <div style={{ width: "100%", display: "flex" }}>
              <a
                role="button"
                className={`my-auto navbar-burger ${
                  isActive ? "is-active" : ""
                }`}
                onClick={() => {
                  setIsActive(!isActive);
                }}
                aria-label="menu"
                aria-expanded="false"
                data-target="navbarBasicExample"
              >
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </a>
            </div>
          </div>

          <div className="navbar-end">
            <div
              className="navbar-end navbar_line my-auto"
              style={{
                width: width >= 1024 && "100%",
                display: width >= 1024 && "flex",
                justifyContent: width >= 1024 && "center",
              }}
            ></div>
          </div>
          <div
            id="navbarBasicExample"
            className={`borderXwidth navbar-menu ${
              isActive ? "is-active" : ""
            }`}
          >
            <div
              className="navbar-end navbar_line my-auto"
              style={{
                width: width >= 1024 && "100%",
                display: width >= 1024 && "flex",
                justifyContent: width >= 1024 && "center",
              }}
            >
              {headeritemleft.map((item, key) => {
                return (
                  <div className="header_wrapper">
                    <Link
                      className="navbar-item header_item hoverheader_item"
                      to={item.headlink}
                    >
                      {item.headname}
                    </Link>
                  </div>
                );
              })}
              <div className="header_wrapper">
                {width > 993 && (
                  <a className="navbar-item logo" href="\">
                    <img src="main_logo.png" alt="logo" />
                  </a>
                )}
              </div>
              {headeritemright.map((item, key) => {
                return (
                  <div className="header_wrapper">
                    <Link
                      className="navbar-item header_item hoverheader_item"
                      to={item.headlink}
                    >
                      {item.headname}
                    </Link>
                  </div>
                );
              })}
              <div className="header_wrapper header_button has-text-left">
                {/* <Button
                  type="primary"
                  className="bgbrown_color get_in"
                  size="large"
                >
                  <Link to={url.Contactus}>GET IN TOUCH</Link>
                </Button> */}
              </div>
            </div>
          </div>
          {/* <div class="navbar-end">
            <div class="navbar-item">
              <div class="buttons">
                <Button
                  type="primary"
                  className="bgbrown_color get_in"
                  size="large"
                >
                  <Link to={url.Contactus}>GET IN TOUCH</Link>
                </Button>
              </div>
            </div>
          </div> */}
        </div>
        {/*
         */}
      </nav>
    </>
  );
};
export default Header;
