import { Button, Col, Form, Input, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { Link } from "react-router-dom";
import { url } from "../../constants/navhome";

const Contactus = () => {
  const onFinish = (formData) => {
    console.log(formData);
  };
  return (
    <>
      <section>
        <div className="section_padding"></div>
        <div>
          <Row>
            <Col xl={12} lg={12} md={12} sm={24} xs={24} className="my-auto">
              <div className="getintouch">
                <div className="section_heading">
                  <h1 className="has-text-centered mb-5">
                    <span className="diff">C</span>ontact Us
                  </h1>
                </div>
                <Form onFinish={onFinish}>
                  <Form.Item
                    className="main_input"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter valid name",
                        pattern: /^[A-Za-z\s\b]+$/,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Your Name"
                      className="contact_input p-3"
                      onc
                    />
                  </Form.Item>
                  {/* <Form.Item
                    name="mobile"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter valid Mobile",
                        pattern: /^[0-9]{10}$/,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Your Mobile"
                      className="contact_input"
                    />
                  </Form.Item> */}
                  <Form.Item
                    name="Message"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter valid email",
                        pattern: /^[A-Za-z0-9._-]+[A-Za-z]+.[A-Za-z]+$/,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Your Email"
                      className="contact_input p-3"
                    />
                  </Form.Item>
                  <Form.Item>
                    <TextArea className="p-3" placeholder="Message" rows={4} />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      className="bgbrown_color get_in"
                      size="large"
                    >
                      <Link to={url.Contactus}>Submit</Link>
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <iframe
                title="Maps"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7361.401796299187!2d73.91102953488769!3d22.702175800000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3960f235596915db%3A0xb11801dc88fd7f55!2sDevgadh%20Baria%2C%20Gujarat%20389380!5e0!3m2!1sen!2sin!4v1661860686358!5m2!1sen!2sin"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};
export default Contactus;
