import React, { useEffect, useState } from "react";
import { Col, Grid, Row, Space } from "antd";
import { Carousel } from "react-responsive-carousel";
import { MdAirportShuttle } from "react-icons/md";
import { FaCar } from "react-icons/fa";
import { FaDollyFlatbed } from "react-icons/fa";
import { AiOutlineWifi } from "react-icons/ai";
import { MdFoodBank } from "react-icons/md";
import { BiFootball } from "react-icons/bi";
import { FaTableTennis } from "react-icons/fa";
import { GiCycling } from "react-icons/gi";
import { GiHiking } from "react-icons/gi";
import { GiBarbecue } from "react-icons/gi";
import { SiPicnic } from "react-icons/si";
import { GiJourney } from "react-icons/gi";
import { GiTennisCourt } from "react-icons/gi";
import { MdOutlineFreeBreakfast } from "react-icons/md";

const { useBreakpoint } = Grid;

const ourservice = [
  {
    icon: <MdAirportShuttle className="is-size-1 brown_color" />,
    title: "Pick Up & Drop",
    // des: " We'll pick up from airport while you comfy on your ride, mus nellentesque habitant.",
  },
  {
    icon: <FaDollyFlatbed className="is-size-1 brown_color" />,
    title: "Room Service",
    // des: "Room tincidunt nis ace park norttito sit amet space, mus nellentesque habitant.",
  },
  {
    icon: <FaCar className="is-size-1 brown_color" />,
    title: "Parking Space",
    // des: "Fusce tincidunt nis ace park norttito sit amet space, mus nellentesque habitant.",
  },
  {
    icon: <MdFoodBank className="is-size-1 brown_color" />,
    title: "Royal Lunch",
    // des: "Eat tincidunt nisa ace park norttito sit amet space, mus nellentesque habitant",
  },
  {
    icon: <AiOutlineWifi className="is-size-1 brown_color" />,
    title: "Fibre Internet",
    // des: "Wifi tincidunt nis ace park norttito sit amet space, mus nellentesque habitant.",
  },
  {
    icon: <MdOutlineFreeBreakfast className="is-size-1 brown_color" />,
    title: "Breakfast",
    // des: "Eat tincidunt nisa ace park norttito sit amet space, mus nellentesque habitant",
  },
];
const activities = [
  {
    icon: <BiFootball className="is-size-1 brown_color" />,
    title: "Football field",
  },
  {
    icon: <FaTableTennis className="is-size-1 brown_color" />,
    title: "Tennis court",
  },
  {
    icon: <GiTennisCourt className="is-size-1 brown_color" />,
    title: "Squash court",
  },
  {
    icon: <GiCycling className="is-size-1 brown_color" />,
    title: "Cycling",
  },
  {
    icon: <GiJourney className="is-size-1 brown_color" />,
    title: "Expedition",
  },
  {
    icon: <GiHiking className="is-size-1 brown_color" />,
    title: "Hiking",
  },
  {
    icon: <GiBarbecue className="is-size-1 brown_color" />,
    title: "Barbeque",
  },
  {
    icon: <SiPicnic className="is-size-1 brown_color" />,
    title: "Picnics",
  },
];
// const belowservice = [
//   {
//     img: "Sources/images/service/1.png",
//     subtitle: "EXPERIENCES",
//     title: "Lounge Area",
//     des: "Restaurant inilla duiman at elit finibus viverra nec a lacus themo the nesudea seneoice misuscipit non sagie the fermen ziverra tristiue duru the ivite dianne onen nivami acsestion augue artine.",
//   },
//   {
//     img: "Sources/images/service/2.png",
//     subtitle: "DISCOVER",
//     title: "Dining Area",
//     des: "Restaurant inilla duiman at elit finibus viverra nec a lacus themo the nesudea seneoice misuscipit non sagie the fermen ziverra tristiue duru the ivite dianne onen nivami acsestion augue artine.",
//   },
//   {
//     img: "Sources/images/service/3.png",
//     subtitle: "EXPERIENCES",
//     title: "Royal Lunch",
//     des: "Restaurant inilla duiman at elit finibus viverra nec a lacus themo the nesudea seneoice misuscipit non sagie the fermen ziverra tristiue duru the ivite dianne onen nivami acsestion augue artine.",
//   },
//   {
//     img: "Sources/images/service/4.png",
//     subtitle: "DISCOVER",
//     title: "Summer Lounge",
//     des: "Restaurant inilla duiman at elit finibus viverra nec a lacus themo the nesudea seneoice misuscipit non sagie the fermen ziverra tristiue duru the ivite dianne onen nivami acsestion augue artine.",
//   },
// ];

// const activityimgs = [
//   {
//     src: "Sources/images/activity/1.png",
//     caption: "Football field",
//   },
//   {
//     src: "Sources/images/activity/2.png",
//     caption: "Tennis court",
//   },
//   {
//     src: "Sources/images/activity/3.png",
//     caption: "Squash court",
//   },
//   {
//     src: "Sources/images/activity/4.png",
//     caption: "Cycling",
//   },
// ];

const carousel1 = [
  {
    src: "Sources/images/area/1.png",
  },
  // {
  //   src: "Sources/images/area/2.png",
  // },
  {
    src: "Sources/images/area/3.png",
  },
];

const carousel2 = [
  {
    src: "Sources/images/area/a.png",
  },
  {
    src: "Sources/images/area/b.png",
  },
  {
    src: "Sources/images/area/c.png",
  },
  {
    src: "Sources/images/area/d.png",
  },
];
const carousel3 = [
  {
    src: "Sources/images/area/8.png",
  },
  {
    src: "Sources/images/area/9.png",
  },
  {
    src: "Sources/images/area/10.png",
  },
];
const carousel4 = [
  {
    src: "Sources/images/area/11.png",
  },
  {
    src: "Sources/images/area/12.png",
  },
  {
    src: "Sources/images/area/13.png",
  },
];

const Services = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const { md, lg } = useBreakpoint();
  const tablet = md && !lg;

  useEffect(() => {
    const widthEvent = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, []);
  return (
    <>
      <section className="service">
        <div className="section_padding"></div>
        <div className="container">
          <div>
            <div className="section_heading has-text-centered">
              <h1 className="has-text-centered">
                <span className="diff">H</span>otel Facilities
              </h1>
              <p className="is-size-6 has-text-centered pt-1 mt-0 sub_menu">
                OUR SERVICES
              </p>
            </div>
            <div className="our_services mt-5 pt-5">
              <Row>
                {ourservice.map((item, key) => {
                  return (
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                      <div className="service_wrapper">
                        <Space direction="vertical" style={{ display: "flex" }}>
                          <div className="has-text-centered service_icon">
                            {item.icon}
                          </div>
                          <p
                            className="is-size-4 mt-3 mb-5 roomprice_big has-text-grey-dark"
                            style={{ lineHeight: "0" }}
                          >
                            {item.title}
                          </p>
                          <p className="has-text-grey">{item.des}</p>
                        </Space>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        </div>
        <div className="section_padding"></div>
      </section>

      <section className="local_activities">
        <div className="container">
          <div className="section_heading">
            <h1 className="has-text-centered ">
              <span className="diff">A</span>ctivities
            </h1>
          </div>
          <div className="mt-5 pt-5">
            <p className="is-size-3 activity_text brown_color pr-5 has-text-centered">
              Enjoy comfort and calm in the city
            </p>
          </div>
          <div className="localacti_box mt-5 pt-5">
            <Row>
              {activities.map((item, key) => {
                return (
                  <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                    <div className="service_wrapper">
                      <Space direction="vertical" style={{ display: "flex" }}>
                        <div className="has-text-centered service_icon">
                          {item.icon}
                        </div>
                        <p
                          className="is-size-4 mt-3 mb-5 roomprice_big has-text-grey-dark"
                          style={{ lineHeight: "0" }}
                        >
                          {item.title}
                        </p>
                        <p className="has-text-grey">{item.des}</p>
                      </Space>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>

        <div className="section_padding"></div>
      </section>

      {/* <section>
        <div className="move_img">
          <div className="section_padding"></div>
        </div>
      </section> */}

      <section>
        <div className="section_padding"></div>
        <div className="container">
          <Row>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              className="bgcreem_color"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="below_service my-auto">
                <p
                  className="is-size-5 mt-3 mb-5 has-text-centered roomprice_big has-text-grey-dark"
                  style={{
                    lineHeight: "1",
                    letterSpacing: "3px",
                  }}
                >
                  EXPERIENCES
                </p>
                <p
                  className="is-size-2 mt-3 mb-5 has-text-centered roomprice_big has-text-grey-dark"
                  style={{ lineHeight: "1" }}
                >
                  Lounge Area
                </p>
                {/* <p className="has-text-justified mt-5 pt-5 has-text-grey">
                        {item.des}
                      </p> */}
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="image_wrapper">
                <Carousel
                  autoPlay={true}
                  infiniteLoop={true}
                  showArrows={false}
                  showThumbs={false}
                  showStatus={false}
                  swipeable={true}
                  emulateTouch={true}
                >
                  {carousel1.map((item, key) => {
                    return (
                      <div style={{ height: "100%", width: "100%" }}>
                        <img
                          src={item.src}
                          alt="404 - Not Given"
                          style={{ height: "100%", width: "100%" }}
                        />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </Col>
          </Row>
          <Row>
            {width < 626 && (
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                className="bgcreem_color"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="below_service my-auto">
                  <p
                    className="is-size-5 mt-3 mb-5 has-text-centered roomprice_big has-text-grey-dark"
                    style={{
                      lineHeight: "1",
                      letterSpacing: "3px",
                    }}
                  >
                    DISCOVER
                  </p>
                  <p
                    className="is-size-2 mt-3 mb-5 has-text-centered roomprice_big has-text-grey-dark"
                    style={{ lineHeight: "1" }}
                  >
                    Dining Area
                  </p>
                  {/* <p className="has-text-justified mt-5 pt-5 has-text-grey">
                        {item.des}
                      </p> */}
                </div>
              </Col>
            )}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="image_wrapper">
                <Carousel
                  autoPlay={true}
                  infiniteLoop={true}
                  showArrows={false}
                  showThumbs={false}
                  showStatus={false}
                  swipeable={true}
                  emulateTouch={true}
                >
                  {carousel2.map((item, key) => {
                    return (
                      <div style={{ height: "100%", width: "100%" }}>
                        <img
                          src={item.src}
                          alt="404 - Not Given"
                          style={{ height: "100%", width: "100%" }}
                        />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </Col>
            {width > 625 && (
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                className="bgcreem_color"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="below_service my-auto">
                  <p
                    className="is-size-5 mt-3 mb-5 has-text-centered roomprice_big has-text-grey-dark"
                    style={{
                      lineHeight: "1",
                      letterSpacing: "3px",
                    }}
                  >
                    DISCOVER
                  </p>
                  <p
                    className="is-size-2 mt-3 mb-5 has-text-centered roomprice_big has-text-grey-dark"
                    style={{ lineHeight: "0" }}
                  >
                    Dining Area
                  </p>
                  {/* <p className="has-text-justified mt-5 pt-5 has-text-grey">
                        {item.des}
                      </p> */}
                </div>
              </Col>
            )}
          </Row>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              className="bgcreem_color"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="below_service my-auto">
                <p
                  className="is-size-5 mt-3 mb-5 has-text-centered roomprice_big has-text-grey-dark"
                  style={{
                    lineHeight: "1",
                    letterSpacing: "3px",
                  }}
                >
                  EXPERIENCES
                </p>
                <p
                  className="is-size-2 mt-3 mb-5 has-text-centered roomprice_big has-text-grey-dark"
                  style={{ lineHeight: "1" }}
                >
                  Royal Lunch
                </p>
                {/* <p className="has-text-justified mt-5 pt-5 has-text-grey">
                        {item.des}
                      </p> */}
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="image_wrapper">
                <Carousel
                  autoPlay={true}
                  infiniteLoop={true}
                  showArrows={false}
                  showThumbs={false}
                  showStatus={false}
                  swipeable={true}
                  emulateTouch={true}
                >
                  {carousel3.map((item, key) => {
                    return (
                      <div style={{ height: "100%", width: "100%" }}>
                        <img
                          src={item.src}
                          alt="404 - Not Given"
                          style={{ height: "100%", width: "100%" }}
                        />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </Col>
          </Row>
          <Row>
            {width < 626 && (
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                className="bgcreem_color"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="below_service my-auto has-text-centered">
                  <p
                    className="is-size-5 mt-3 mb-5 has-text-centered roomprice_big has-text-grey-dark"
                    style={{
                      lineHeight: "1",
                      letterSpacing: "3px",
                    }}
                  >
                    DISCOVER
                  </p>
                  <p
                    className="is-size-2 mt-3 mb-5 has-text-centered roomprice_big has-text-grey-dark"
                    style={{ lineHeight: "1" }}
                  >
                    Summer Lounge
                  </p>
                  {/* <p className="has-text-justified mt-5 pt-5 has-text-grey">
                        {item.des}
                      </p> */}
                </div>
              </Col>
            )}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="image_wrapper">
                <Carousel
                  autoPlay={true}
                  infiniteLoop={true}
                  showArrows={false}
                  showThumbs={false}
                  showStatus={false}
                  swipeable={true}
                  emulateTouch={true}
                >
                  {carousel4.map((item, key) => {
                    return (
                      <div style={{ height: "100%", width: "100%" }}>
                        <img
                          src={item.src}
                          alt="404 - Not Given"
                          style={{ height: "100%", width: "100%" }}
                        />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </Col>
            {width > 625 && (
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                className="bgcreem_color"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="below_service my-auto">
                  <p
                    className="is-size-5 mt-3 mb-5 has-text-centered roomprice_big has-text-grey-dark"
                    style={{
                      lineHeight: "1",
                      letterSpacing: "3px",
                    }}
                  >
                    DISCOVER
                  </p>
                  <p
                    className="is-size-2 mt-3 mb-5 has-text-centered roomprice_big has-text-grey-dark"
                    style={{ lineHeight: "1" }}
                  >
                    Summer Lounge
                  </p>
                  {/* <p className="has-text-justified mt-5 pt-5 has-text-grey">
                        {item.des}
                      </p> */}
                </div>
              </Col>
            )}
          </Row>
        </div>
        <div className="section_padding"></div>
      </section>

      {/* <section>
        <div className="section_padding"></div>
        <div className="container">
          {belowservice.map((item, key) => {
            return (
              <Row>
                {width < 769 && (
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                    className="bgcreem_color service_wrapper"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div className="below_service my-auto has-text-centered">
                      <p
                        className="is-size-5 mt-3 mb-5 has-text-centered roomprice_big has-text-grey-dark"
                        style={{
                          lineHeight: "1",
                          letterSpacing: "3px",
                        }}
                      >
                        {item.subtitle}
                      </p>
                      <p
                        className="is-size-2 mt-3 mb-5 has-text-centered roomprice_big has-text-grey-dark"
                        style={{ lineHeight: "0" }}
                      >
                        {item.title}
                      </p>
                    </div>
                  </Col>
                )}
                {key % 2 === 0 && (
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <div className="image_wrapper">
                      <img
                        src={item.img}
                        alt="404 -Not Given"
                        style={{ width: "100%" }}
                        className="service_img"
                      />
                    </div>
                  </Col>
                )}
                {width > 768 && (
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                    className="bgcreem_color"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div className="below_service my-auto">
                      <p
                        className="is-size-5 mt-3 mb-5 has-text-centered roomprice_big has-text-grey-dark"
                        style={{
                          lineHeight: "1",
                          letterSpacing: "3px",
                        }}
                      >
                        {item.subtitle}
                      </p>
                      <p
                        className="is-size-2 mt-3 mb-5 has-text-centered roomprice_big has-text-grey-dark"
                        style={{ lineHeight: "0" }}
                      >
                        {item.title}
                      </p>
                    </div>
                  </Col>
                )}
                {key % 2 !== 0 && (
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <div className="image_wrapper">
                      <img
                        src={item.img}
                        alt="404 -Not Given"
                        style={{ width: "100%" }}
                        className="service_img"
                      />
                    </div>
                  </Col>
                )}
              </Row>
            );
          })}
        </div>
        <div className="section_padding"></div>
      </section> */}

      {/* <section className="local_activities">
        <div className="container">
          <div className="section_heading">
            <h1 className="has-text-centered ">
              <span className="diff">A</span>ctivities
            </h1>
          </div>
          <div className="mt-5 pt-5">
            <p className="is-size-3 activity_text brown_color pr-5 has-text-centered">
              Enjoy comfort and calm in the city
            </p>
          </div>
          <div className="localacti_box mt-5 pt-5">
            <Row style={{ display: "flex", justifyContent: "center" }}>
              {activityimgs.map((item, key) => {
                return (
                  <Col xxl={12} md={12} lg={12} sm={24} xs={24}>
                    <div className="activitybox_wrapper">
                      <div class="activity_img">
                        <img
                          src={item.src}
                          alt="404 - Not Given"
                          class="activity_image"
                        />
                        <div class="content">
                          <div class=" is-size-3 text curly_text">
                            {item.caption}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>

        <div className="section_padding"></div>
      </section> */}
    </>
  );
};

export default Services;
