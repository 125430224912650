export const url = {
  home: "/",
  Services: "/services",
  Aboutus: "/about_us",
  Gallery: "/gallery",
  Policy: "/policy",
  Contactus: "/contact_us",
  Roomdetails: "/roomdetails",
  Room: "/room",
  NearbyAttraction: "/nearbyAttraction",
  Restaurant: "/restaurant",
  Inquiry: "/inquiry",
};
