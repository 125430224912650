import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import "./about_us.css";
import { homecard } from "../../constants/homecard";

const Aboutus = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const widthEvent = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, []);
  return (
    <>
      <section className="banner_img">
        {/* <div className="img_text">
          A hotel isnt Like a home, but it's better than being a house guest.
        </div> */}
      </section>
      <section className="amenities">
        <div className="section_padding"></div>
        <div className="container">
          <div className="section_heading">
            <h1 className="has-text-centered ">
              <span className="diff">T</span>he Rajmahal
            </h1>
          </div>
          <div className="cards">
            {homecard.map((item, key) => {
              return (
                <Row justify="start" className="card_formate">
                  {width < 769 && (
                    <Col xxl={12} md={12} lg={12} sm={24} xs={24}>
                      <div className="image_wrapper div_images">
                        <img
                          src={item.src}
                          alt={item.alt || "404 - Not Found"}
                          className="about_image"
                        />
                      </div>
                    </Col>
                  )}
                  {key % 2 === 0 && (
                    <Col xxl={12} md={12} lg={12} sm={24} xs={24}>
                      <div className="textContainer">
                        {/* <p className="splitItemHeading is-size-5 has-text-weight-semibold brown_color"></p> */}
                        <p className="pt-4 mt-3 is-size-6 has-text-justified">
                          {item.para}
                        </p>
                      </div>
                    </Col>
                  )}
                  {width > 768 && (
                    <Col xxl={12} md={12} lg={12} sm={24} xs={24}>
                      <div className="image_wrapper div_images">
                        <img
                          src={item.src}
                          alt={item.alt || "404 - Not Found"}
                          className="about_image"
                        />
                      </div>
                    </Col>
                  )}
                  {key % 2 !== 0 && (
                    <Col xxl={12} md={12} lg={12} sm={24} xs={24}>
                      <div className="textContainer">
                        {/* <p className="splitItemHeading is-size-5 has-text-weight-semibold brown_color"></p> */}
                        <p className="pt-4 mt-3 is-size-6 has-text-justified">
                          {item.para}
                        </p>
                      </div>
                    </Col>
                  )}
                </Row>
              );
            })}
          </div>
        </div>

        <div className="section_padding"></div>
      </section>
    </>
  );
};

export default Aboutus;
