import React from "react";

const NearbyAttraction = () => {
  return (
    <>
      <section>
        <div className="section_padding"></div>
        <div className="container">
          <div className="mt-5 pt-5">
            <div className="section_heading has-text-centered">
              <h1 className="has-text-centered">
                <span className="diff">R</span>atamahal
              </h1>
              <p className="is-size-6 has-text-centered pt-1 mt-0 sub_menu">
                90 MINS
              </p>
            </div>
            <div className="pt-5 picnic_content">
              <p>
                This sanctuary harbours maximum population of sloth bears in the
                entire state, which is the star attraction in the wilds of
                Ratanmahals. The sanctuary falls in the Dahod district of
                Central Gujarat and is located very close to the tribal towns,
                Baria of Dahod district and Chhota Udepur of Vadodara district.
                This area was declared as a wildlife sanctuary in March 1982.
                The sanctuary falls on the border of Gujarat with Madhya
                Pradesh. The actual habitat of the Sloth bear, therefore,
                extends into Madhya Pradesh. The pristine beauty of forests in
                this small tract with rugged topography gives the feel of a hill
                station to wildlife enthusiasts.
              </p>
            </div>
          </div>
          <div className="mt-5 pt-5">
            <div className="section_heading has-text-centered">
              <h1 className="has-text-centered">
                <span className="diff">U</span>dalmavda
              </h1>
              <p className="is-size-6 has-text-centered pt-1 mt-0 sub_menu">
                45 MINS
              </p>
            </div>
            <div className="pt-5 picnic_content">
              <p>
                Udal Mahal is a sanctuary near Devgadh Baria. The sanctuary
                falls in the Dahod district of Gujarat. The Mira waterfall is an
                key attraction inside the sanctuary.The seasonal water fall
                which is 4 km away trek distance gives an outstanding view.
                <p>
                  Udal mahal is in the Ratnamahal Sloth bear sanctuary. This
                  place is been developed by the Forest department of Gujarat.
                  The eastern hills of Gujarat are spectacular and is a meeting
                  point of Vindhyanchal, Aravalli and Satpura. You can find
                  sloth bears and various other animals in the sanctuary. Not
                  only this, but also there is a accommodation facility which
                  covers a large area and both tents and cottages are available
                  for stay. It provides a great opportunity to stay connected
                  with the nature. Inside the dense forest, besides waterfalls
                  and under the open sky is something people want to hide out
                  with.
                </p>
              </p>
            </div>
          </div>
          <div className="mt-5 pt-5">
            <div className="section_heading has-text-centered">
              <h1 className="has-text-centered">
                <span className="diff">S</span>agtala
              </h1>
              <p className="is-size-6 has-text-centered pt-1 mt-0 sub_menu">
                30 MINS
              </p>
            </div>
            <div className="pt-5 picnic_content">
              <p>
                Sagtala is a Village in Devgad Bariya Taluka in Dohad District
                of Gujarat State, India. It is located 55 KM towards west from
                District head quarters Dahod. 16 KM from . 176 KM from State
                capital Gandhinagar.Fangiya ( 4 KM ) , Dabhva ( 4 KM ) , Navi
                Bedi ( 5 KM ) , Satkunda ( 6 KM ) , Nadatod ( 6 KM ) are the
                nearby Villages to Sagtala. Sagtala is surrounded by Chhota
                Udepur Taluka towards South , Dhanpur Taluka towards East ,
                Ghoghamba Taluka towards west , Jambughoda Taluka towards west .
              </p>
            </div>
          </div>
          <div className="mt-5 pt-5">
            <div className="section_heading has-text-centered">
              <h1 className="has-text-centered">
                <span className="diff">D</span>udhana Farm House
              </h1>
              <p className="is-size-6 has-text-centered pt-1 mt-0 sub_menu is-uppercase">
                Rajmahal Baria
              </p>
            </div>
            <div className="pt-5 picnic_content">
              <p>
                Dudhana is a quaint cottage surrounded by amla and lemon trees.
              </p>
            </div>
          </div>
          <div className="mt-5 pt-5">
            <div className="section_heading has-text-centered">
              <h1 className="has-text-centered">
                <span className="diff">A</span>vanti - Private Residence
              </h1>
              <p className="is-size-6 has-text-centered pt-1 mt-0 sub_menu is-uppercase">
                Rajmahal Baria
              </p>
            </div>
            <div className="pt-5 picnic_content">
              <p>
                Avanti is 20 acres of thick forest. It's a get away spot of
                maharani's of Devgadh Baria. Later converted into the residence
                for the royal family who stayed there till mardch 2013 an the
                shifted to Rajmahal.
              </p>
            </div>
          </div>
        </div>
        <div className="section_padding"></div>
      </section>
    </>
  );
};
export default NearbyAttraction;
