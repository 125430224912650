import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { Route, BrowserRouter, Routes, useLocation } from "react-router-dom";
import Aboutus from "./components/about_us/about_us";
import Gallery from "./components/gallery/gallery";
import Home from "./components/home/home";
import Services from "./components/services/services";
import { url } from "./constants/navhome";
import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import "antd/dist/antd.css";
import "bulma/css/bulma.css";
import "./main.css";
import Policy from "./components/policy/policy";
import Contactus from "./components/contact/contact_us";
import Room from "./components/Rooms/Room";
import Roomdetails from "./components/Rooms/Room_details";
import NearbyAttraction from "./components/nearbyAttraction/nearbyattraction";
import Restaurant from "./components/Restaurant/restaurant";
import Inquiry from "./components/contact/inquiry";

const Main = () => {
  const path = useLocation().pathname;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, [path]);
  return (
    <>
      <Header />
      <Routes>
        <Route path={url.home} element={<Home />} />
        <Route path={url.Gallery} element={<Gallery />} />
        <Route path={url.Aboutus} element={<Aboutus />} />
        <Route path={url.Services} element={<Services />} />
        <Route path={url.Policy} element={<Policy />} />
        <Route path={url.Contactus} element={<Contactus />} />
        <Route path={url.Room} element={<Room />} />
        <Route path={url.Roomdetails} element={<Roomdetails />} />
        <Route path={url.NearbyAttraction} element={<NearbyAttraction />} />
        <Route path={url.Restaurant} element={<Restaurant />} />
        <Route path={url.Inquiry} element={<Inquiry />} />
      </Routes>
      <Footer />
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Main />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
