import { Button, Col, Row, Space } from "antd";
import { AiOutlineTeam } from "react-icons/ai";
import { MdOutlineFreeBreakfast } from "react-icons/md";
import { AiOutlineWifi } from "react-icons/ai";
import { IoMdBed } from "react-icons/io";
import { MdOutlineCall } from "react-icons/md";
import React, { useEffect, useState } from "react";
import { url } from "../../constants/navhome";
import { Link } from "react-router-dom";

const Roomacard = [
  // {
  //   // roomimg: "Sources/images/room/3.png",
  //   // tword: "C",
  //   // title: "handrakha Suite",
  //   // des: "Hotel non lorem ac erat suscipit bibendum nulla facilisi. Sedeuter nunc volutpat miss sapien...",
  // },
  {
    roomimg: "Sources/images/room/3.png",
    tword: "S",
    title: "unken Garden Suite",
    // des: "Hotel non lorem ac erat suscipit bibendum nulla facilisi. Sedeuter nunc volutpat miss sapien...",
  },
  {
    roomimg: "Sources/images/room/1.png",
    tword: "M",
    title: "aharani Suite",
    // des: "Hotel non lorem ac erat suscipit bibendum nulla facilisi. Sedeuter nunc volutpat miss sapien...",
  },
  {
    roomimg: "Sources/images/room/2.png",
    tword: "M",
    title: "aharaja Suite",
    // des: "Hotel non lorem ac erat suscipit bibendum nulla facilisi. Sedeuter nunc volutpat miss sapien...",
  },
  {
    roomimg: "Sources/images/room/3.png",
    tword: "R",
    title: "oyal Suite",
    // des: "Hotel non lorem ac erat suscipit bibendum nulla facilisi. Sedeuter nunc volutpat miss sapien...",
  },
];

const Room = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const widthEvent = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, []);
  return (
    <>
      <section className="mobile_margin">
        <div className="container">
          <div className="section_padding"></div>
          <div className="section_heading">
            <h1 className="has-text-centered mb-2">
              <span className="diff">R</span>ooms
            </h1>
            <p className="has-text-centered mb-5">
              The hotel has lavish suites over looking the entire property with
              modern touch. Traditional and contemporary in design and ambiance,
              the suites are carefully designed to take the inconvenience out of
              travel.
            </p>
          </div>
        </div>
      </section>
      {Roomacard.map((item, key) => {
        return (
          <section>
            {width < 992 && (
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <img
                  src={item.roomimg}
                  alt="404 - Not Found"
                  className="roomcard_image"
                  style={{ width: "100%", height: "100%" }}
                />
              </Col>
            )}
            <Row className="room_cards">
              {key % 2 === 0 && (
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={12}
                  xl={12}
                  className="room_facilities"
                >
                  <div className="roomcard_wrapper1">
                    <div className="caption">
                      <p
                        className="is-size-2 mt-3 mb-5 roomprice_big curly_text brown_color"
                        style={{ lineHeight: "0.5" }}
                      >
                        <span className="diff">{item.tword}</span>
                        {item.title}
                      </p>
                    </div>
                    <div>
                      <p className="mt-5 pt-3 is-size-6">{item.des}</p>
                    </div>
                    <div className="mt-5 pt-3">
                      <Row>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                          <div>
                            <Space direction="horizontal">
                              <AiOutlineTeam className="brown_color" />
                              <p className="has-text-grey-dark is-size-6">
                                1-2 Persons
                              </p>
                            </Space>
                          </div>
                          <div>
                            <Space direction="horizontal" className="pt-2">
                              <MdOutlineCall className="brown_color" />
                              <p className="has-text-grey-dark is-size-6">
                                Telephone
                              </p>
                            </Space>
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                          <div>
                            <Space direction="horizontal">
                              <AiOutlineWifi className="brown_color" />
                              <p className="has-text-grey-dark is-size-6">
                                Free Wifi
                              </p>
                            </Space>
                          </div>
                          <div>
                            <Space direction="horizontal" className="pt-2">
                              <IoMdBed className="brown_color" />
                              <p className="has-text-grey-dark is-size-6">
                                Living Room
                              </p>
                            </Space>
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                          <div>
                            <Space direction="horizontal">
                              <IoMdBed className="brown_color" />
                              <p className="has-text-grey-dark is-size-6">
                                Extra Bed
                              </p>
                            </Space>
                          </div>
                          <div>
                            <Space direction="horizontal" className="pt-2">
                              <MdOutlineFreeBreakfast className="brown_color" />
                              <p className="has-text-grey-dark is-size-6">
                                Coffee Service
                              </p>
                            </Space>
                          </div>
                        </Col>
                      </Row>
                      <div className="mt-5 has-text-right mr-5">
                        <Button
                          type="primary"
                          className="bgbrown_color get_in"
                          size="medium"
                        >
                          <Link to={url.Roomdetails}>Details</Link>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              )}

              {width > 993 && (
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <img
                    src={item.roomimg}
                    alt="404 - Not Found"
                    className="roomcard_image"
                    style={{ width: "100%", height: "100%" }}
                  />
                </Col>
              )}
              {key % 2 !== 0 && (
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={12}
                  xl={12}
                  className="room_facilities"
                >
                  <div className="roomcard_wrapper2">
                    <div className="caption">
                      <p
                        className="is-size-2 mt-3 mb-5 roomprice_big curly_text brown_color"
                        style={{ lineHeight: "0" }}
                      >
                        <span className="diff">{item.tword}</span>
                        {item.title}
                      </p>
                    </div>
                    <div>
                      <p className="mt-5 pt-3 is-size-6">{item.des}</p>
                    </div>
                    <div className="mt-5 pt-3">
                      <Row>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                          <div>
                            <Space direction="horizontal">
                              <AiOutlineTeam className="brown_color" />
                              <p className="has-text-grey-dark is-size-6">
                                1-2 Persons
                              </p>
                            </Space>
                          </div>
                          <div>
                            <Space direction="horizontal" className="pt-2">
                              <MdOutlineCall className="brown_color" />
                              <p className="has-text-grey-dark is-size-6">
                                Telephone
                              </p>
                            </Space>
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                          <div>
                            <Space direction="horizontal">
                              <AiOutlineWifi className="brown_color" />
                              <p className="has-text-grey-dark is-size-6">
                                Free Wifi
                              </p>
                            </Space>
                          </div>
                          <div>
                            <Space direction="horizontal" className="pt-2">
                              <IoMdBed className="brown_color" />
                              <p className="has-text-grey-dark is-size-6">
                                Living Room
                              </p>
                            </Space>
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                          <div>
                            <Space direction="horizontal">
                              <IoMdBed className="brown_color" />
                              <p className="has-text-grey-dark is-size-6">
                                Extra Bed
                              </p>
                            </Space>
                          </div>
                          <div>
                            <Space direction="horizontal" className="pt-2">
                              <MdOutlineFreeBreakfast className="brown_color" />
                              <p className="has-text-grey-dark is-size-6">
                                Coffee Service
                              </p>
                            </Space>
                          </div>
                        </Col>
                      </Row>
                      <div className="mt-5 has-text-right mr-5">
                        <Button
                          type="primary"
                          className="bgbrown_color get_in"
                          size="medium"
                        >
                          <Link to={url.Roomdetails}>Details</Link>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              )}
            </Row>

            <div className="section_padding"></div>
          </section>
        );
      })}
    </>
  );
};
export default Room;
