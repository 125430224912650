import React from "react";
import { Col, Row } from "antd";
import { MdArrowForwardIos, MdLocationOn } from "react-icons/md";
import { Link } from "react-router-dom";
import { url } from "../../constants/navhome";
import { AiOutlineMail } from "react-icons/ai";
import { BiPhoneCall } from "react-icons/bi";
import { BsInstagram } from "react-icons/bs";
import { Layout } from "antd";

const { Footer: AntFooter } = Layout;

const Footer = () => {
  return (
    <>
      <section className="upper_foot">
        <div className="upper_footer bgbrown_color">
          <div className="container">
            <Row
              justify="start"
              className="mt-5 is-flex is-justify-content-center"
            >
              <Col
                xxl={8}
                lg={8}
                md={8}
                sm={24}
                xs={24}
                className="mb-5 is-flex is-justify-content-center"
              >
                <div className="call_us">
                  <div className="call_img">
                    <MdLocationOn className="has-text-white icon brown_color" />
                  </div>
                  <div className="call_text">
                    <h4>AHMEDABAD</h4>
                    <h6>169 Kms( approx)</h6>
                  </div>
                </div>
              </Col>
              <Col
                xxl={8}
                lg={8}
                md={8}
                sm={24}
                xs={24}
                className="mb-5 is-flex is-justify-content-center"
              >
                <div className="call_us">
                  <div className="call_img">
                    <MdLocationOn className="icon has-text-white" />
                  </div>
                  <div className="call_text">
                    <h4>
                      <a
                        href="mailto:rajmahaldevgadhbaria@gmail.com"
                        className="has-text-white"
                      >
                        VADODARA
                      </a>
                    </h4>
                    <h6>103 Kms (approx)</h6>
                  </div>
                </div>
              </Col>
              <Col
                xxl={8}
                lg={8}
                md={8}
                sm={24}
                xs={24}
                className="mb-5 is-flex is-justify-content-center"
              >
                <div className="call_us">
                  <div className="call_img">
                    <MdLocationOn className="icon has-text-white" />
                  </div>
                  <div className="call_text">
                    <h4>SURAT</h4>
                    <h6>169 Kms (approx)</h6>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
      <AntFooter className="main_footer">
        <div className="section_padding"></div>
        <div className="container">
          <Row>
            <Col xxl={6} lg={6} md={12} sm={24} xs={24}>
              <div className="first_col">
                <h3 className="brown_color pb-4 mt-3">ABOUT US</h3>
                <p className="foot_text has-text-justified	">
                  Welcome to RajMahal. We are located centrally in the heart of
                  Gujarat. Rajmahal is a Royal residence that offers lavishly
                  furnished suites with sprawling gardens. An experience full of
                  erstwhile grandeur and luxury.
                </p>
              </div>
            </Col>
            <Col xxl={6} lg={4} md={12} sm={24} xs={24}>
              <div className="second_col">
                <h3 className="brown_color pb-4 mt-3">USEFUL LINKS</h3>
                <div>
                  <ul>
                    <li className="footer_link">
                      <Link to={url.Aboutus}>
                        <MdArrowForwardIos className="arrow_icon brown_color" />
                        <span className="pl-2">About Us</span>
                      </Link>
                    </li>
                    <li className="footer_link">
                      <Link to={url.Services}>
                        <MdArrowForwardIos className="arrow_icon brown_color" />
                        <span className="pl-2">Services</span>
                      </Link>
                    </li>
                    {/* <li className="footer_link">
                      <Link to={url.Restaurant}>
                        <MdArrowForwardIos className="arrow_icon brown_color" />
                        <span className="pl-2">Restaurant</span>
                      </Link>
                    </li> */}
                    <li className="footer_link">
                      <Link to={url.Policy}>
                        <MdArrowForwardIos className="arrow_icon brown_color" />
                        <span className="pl-2">Policy</span>
                      </Link>
                    </li>
                    <li className="footer_link">
                      <Link to={url.Contactus}>
                        <MdArrowForwardIos className="arrow_icon brown_color" />
                        <span className="pl-2">Contact Us</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col xxl={6} lg={8} md={12} sm={24} xs={24}>
              <div className="third_col">
                <h3 className="brown_color pb-4 mt-3">CONTACT US</h3>
                <div>
                  <ul>
                    <li className="footer_link">
                      <a href="tel:+91 7940020901" rel="noreferrer">
                        <BiPhoneCall className="arrow_icon brown_color" />
                        <span className="pl-2">+91 7940020901</span>
                      </a>
                    </li>
                    <li className="footer_link">
                      <a href="tel:+91 8799419464" rel="noreferrer">
                        <BiPhoneCall className="arrow_icon brown_color" />
                        <span className="pl-2">+91 8799419464</span>
                      </a>
                    </li>
                    <li className="footer_link">
                      <a
                        href="mailto:rajmahaldevgadhbaria@gmail.com"
                        rel="noreferrer"
                      >
                        <AiOutlineMail className="arrow_icon brown_color" />
                        <span className="pl-2">
                          rajmahaldevgadhbaria@gmail.com
                        </span>
                      </a>
                    </li>
                    <li className="footer_link">
                      <a
                        target="_blank"
                        href="https://instagram.com/baria.rajmahal?igshid=YmMyMTA2M2Y="
                        rel="noreferrer"
                      >
                        <BsInstagram className="arrow_icon brown_color" />
                        <span className="pl-2">@baria.rajmahal</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col xxl={6} lg={6} md={12} sm={24} xs={24}>
              <iframe
                title="Maps"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7361.401796299187!2d73.91102953488769!3d22.702175800000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3960f235596915db%3A0xb11801dc88fd7f55!2sDevgadh%20Baria%2C%20Gujarat%20389380!5e0!3m2!1sen!2sin!4v1661860686358!5m2!1sen!2sin"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Col>
          </Row>
        </div>
        <div className="section_padding"></div>
      </AntFooter>
      <section className="lower_foot">
        <div className="lower_footer bgbrown_color">
          <div className="container">
            <p className="has-text-white lower_text">
              @2022&nbsp;
              <a
                href="https://outleadsolutions.com/"
                className="has-text-white"
                target="blank"
              >
                Outlead solution.&nbsp;
              </a>
              All rights reserved
            </p>
          </div>
        </div>
      </section>
    </>
  );
};
export default Footer;
