import React, { useEffect, useState } from "react";
import { Button, Col, Image, Row } from "antd";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { ImageList } from "@mui/material";
import ImageListItem from "@mui/material/ImageListItem";
import { Link } from "react-router-dom";
import { url } from "../../constants/navhome";
import { browseGallery } from "../../constants/browseGallery";
import "./home.css";

const Home = () => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, []);

  let cols = 6;
  if (width < 600) {
    cols = 2;
  } else if (width < 1200) {
    cols = 3;
  }
  return (
    <>
      <section className="banner_carousel">
        <Carousel
          className="main_slide"
          autoPlay={true}
          infiniteLoop={true}
          emulateTouch={true}
          showArrows={false}
          showThumbs={false}
          showStatus={false}
        >
          <div className="allslider">
            <img src="Sources/images/a/1.png" alt="404 not" />
          </div>
          <div className="allslider">
            <img src="Sources/images/a/2.png" alt="404 not" />
          </div>
          <div className="allslider">
            <img src="Sources/images/a/3.png" alt="404 not" />
          </div>
        </Carousel>
      </section>

      <section className="Welcometoparadise">
        <div className="section_padding"></div>
        <div className="container">
          <div className="section_heading">
            <h1 className="has-text-centered ">
              <span className="diff">T</span>he Rajmahal
            </h1>
            {/* <p className="is-size-6 has-text-centered pt-0 mt-0">
              Discover the finest accommodation
            </p> */}
          </div>
          {/* <div>
            <p className="pt-5 is-size-5 mx-3 welcome_text">
              The family of Devgadh Baria originates from the Khichi Chauhan
              dynasty, descendants of Prithviraj Chauhan. Devgadh Baria town was
              established in 1524 by Maharawal Dungar Singh Ji grandson of
              Maharawal Patai Rawal, after escaping the conquest by Mohammad
              Begada in Pavagadh. Rest of the geneology can be followed on
              Wikipedia.
            </p>
          </div> */}
        </div>
        <div className="section_padding"></div>
      </section>

      {/* <section className="about">
        
        <div className="section_padding"></div>
        <div className="container">
          <div className="section_heading">
            <h1 className="has-text-centered mb-5 pb-3">
              <span className="diff ">A</span>bout Us
            </h1>
          </div>
          <Row>
            <Col xxl={12} lg={12} md={12} sm={24} xs={24} className="my-auto">
              <div className="img_height">
                <img
                  src="Sources/images/about/5.png"
                  alt="404 - Not Found"
                  className="about_image_main"
                />
              </div>
            </Col>
            <Col xxl={12} lg={12} md={12} sm={24} xs={24}>
              <p className="about_text has-text-justified	my-auto">
                The family of Devgadh Baria originates from the Khichi Chauhan
                dynasty, descendants of Prithviraj Chauhan. Devgadh Baria town
                was establish ed in1524 by Maharawal Dungar Singh Ji grandson of
                Maharawal Patai Rawal, after escaping the conquest by Mohammad
                Begada in Pavagadh. Rest of the geneology can be followed on
                wikipedia.
                <br />
                <Button
                  type="primary"
                  className="mt-4 bgbrown_color knowmore_button"
                  size="large"
                >
                  <Link to={url.Aboutus}>KNOW MORE</Link>
                </Button>
              </p>
            </Col>
          </Row>
        </div>
        <div className="section_padding"></div>
        <div className="section_padding"></div>
      </section> */}

      <section className="about">
        <div className="section_padding"></div>
        <div className="container">
          <div className="section_heading">
            {/* <h1 className="has-text-centered mb-5 pb-3">
              <span className="diff ">A</span>bout Us
            </h1> */}
          </div>
          <Row>
            <Col
              xxl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
              className="my-auto"
              style={{ overflow: "hidden" }}
            >
              {width < 1023 && (
                <div className="img_height">
                  <img
                    src="Sources/images/about/6.png"
                    alt="404 - Not Found"
                    className="about_image_main"
                  />
                </div>
              )}
              {width > 1023 && (
                <div class="image-3d-effect">
                  <div class="side left"></div>
                  <div class="side right"></div>
                  <div class="images">
                    <div
                      class="front_image"
                      style={{
                        backgroundImage: "url(/Sources/images/about/6.png)",
                      }}
                    ></div>

                    <div
                      class="back_image"
                      style={{
                        backgroundImage: "url(/Sources/images/about/7.png)",
                      }}
                    ></div>
                  </div>
                </div>
              )}
            </Col>
            <Col xxl={12} lg={12} md={12} sm={24} xs={24}>
              <p className="about_text has-text-justified	my-auto">
                The family of Devgadh Baria originates from the Khichi Chauhan
                dynasty, descendants of Prithviraj Chauhan. Devgadh Baria town
                was establish ed in1524 by Maharawal Dungar Singh Ji grandson of
                Maharawal Patai Rawal, after escaping the conquest by Mohammad
                Begada in Pavagadh. Rest of the geneology can be followed on
                wikipedia.
                <br />
                <Button
                  type="primary"
                  className="mt-4 bgbrown_color knowmore_button"
                  size="large"
                >
                  <Link to={url.Aboutus}>KNOW MORE</Link>
                </Button>
              </p>
            </Col>
          </Row>
        </div>
        <div className="section_padding"></div>
        <div className="section_padding"></div>
      </section>

      {/* <section>
        <div className="move_img">
          <div className="section_padding"></div>
        </div>
      </section> */}

      <section className="experiences">
        <div className="section_padding"></div>
        <div className="container">
          <div className="section_heading">
            <h1 className="has-text-centered pb-5">
              <span className="diff">N</span>earby Attractions
            </h1>
          </div>
          <div>
            <div className="site-card-wrapper m-4">
              <Row gutter={16}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Link
                    to={url.NearbyAttraction}
                    className="has-text-white has-text-right"
                  >
                    <div className="item">
                      <div className="nearplace_img">
                        <img
                          src="Sources/images/attraction/ratanmahal.png"
                          alt="404 - Not Given"
                        />
                      </div>
                      <div class="con">
                        <p className="is-size-4 has-text-white">Ratanmahal</p>
                        <p className="is-size-6 pb-3 has-text-white">90 Mins</p>
                        <div class="near_line"></div>
                        <div class="row facilities">
                          <div class="col col-md-5 text-left">
                            <div class="permalink has-text-left">
                              <p className="attraction_content has-text-white has-text-right">
                                Details
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Link
                    to={url.NearbyAttraction}
                    className="has-text-white has-text-right"
                  >
                    <div className="item">
                      <div className="nearplace_img">
                        <img
                          src="Sources/images/attraction/udavmahal.jpg"
                          alt="404 - Not Given"
                        />
                      </div>
                      <div class="con">
                        <p className="is-size-4 has-text-white">Udalmavda</p>
                        <p className="is-size-6 pb-3 has-text-white">45 Mins</p>
                        <div class="near_line"></div>
                        <div class="row facilities">
                          <div class="col col-md-5 text-left">
                            <div class="permalink has-text-left">
                              <p className="attraction_content has-text-white has-text-right">
                                Details
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Link
                    to={url.NearbyAttraction}
                    className="has-text-white has-text-right"
                  >
                    <div className="item">
                      <div className="nearplace_img">
                        <img
                          src="Sources/images/attraction/sagtala.jfif"
                          alt="404 - Not Given"
                        />
                      </div>
                      <div class="con">
                        <p className="is-size-4 has-text-white">Sagtala</p>
                        <p className="is-size-6 pb-3 has-text-white">30 Mins</p>
                        <div class="near_line"></div>
                        <div class="row facilities">
                          <div class="col col-md-5 text-left">
                            <div class="permalink has-text-left">
                              <p className="attraction_content has-text-white has-text-right">
                                Details
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mt-5">
                  <Link
                    to={url.NearbyAttraction}
                    className="has-text-white has-text-right"
                  >
                    <div className="item">
                      <div className="nearplace_img">
                        <img
                          src="Sources/images/picnic_spot/4.png"
                          alt="404 - Not Given"
                        />
                      </div>
                      <div class="con">
                        <p className="is-size-4 has-text-white">
                          Dudhana Farm House
                        </p>
                        <p className="is-size-6 pb-3 has-text-white">
                          Rajmahal Baria
                        </p>
                        <div class="near_line"></div>
                        <div class="row facilities">
                          <div class="col col-md-5 text-left">
                            <div class="permalink has-text-left">
                              <p className="attraction_content has-text-white has-text-right">
                                Details
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mt-5">
                  <Link
                    to={url.NearbyAttraction}
                    className="has-text-white has-text-right"
                  >
                    <div className="item">
                      <div className="nearplace_img">
                        <img
                          src="Sources/images/picnic_spot/6.png"
                          alt="404 - Not Given"
                        />
                      </div>
                      <div class="con">
                        <p className="is-size-4 has-text-white has-text-white">
                          Avanti - Private Residence
                        </p>
                        <p className="is-size-6 pb-3 has-text-white">
                          Rajmahal Baria
                        </p>
                        <div class="near_line"></div>
                        <div class="row facilities">
                          <div class="col col-md-5 text-left">
                            <div class="permalink has-text-left">
                              <p className="attraction_content has-text-white has-text-right">
                                Details
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="section_padding"></div>
      </section>

      <section className="browse_gallery">
        <div className="section_padding"></div>
        <div className="container">
          <div className="section_heading">
            <h1 className="has-text-centered mb-5 pb-3">
              <span className="diff">B</span>rowse our Gallery
            </h1>
          </div>
        </div>
        <div>
          <ImageList cols={cols} className="Py-4">
            {browseGallery.map((item, key) => {
              return (
                <ImageListItem>
                  <Image src={item.src} />
                </ImageListItem>
              );
            })}
          </ImageList>
          <div className="has-text-centered">
            <Button
              type="primary"
              size="large"
              className="mt-4 bgbrown_color knowmore_button"
            >
              <Link to={url.Gallery}>View More</Link>
            </Button>
          </div>
        </div>
        <div className="section_padding"></div>
      </section>
    </>
  );
};

export default Home;
