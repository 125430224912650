import { Col, Row, Space } from "antd";
import { AiOutlineCheck } from "react-icons/ai";
import React from "react";

const Roomdetails = () => {
  return (
    <>
      <section className="mobile_margin">
        <div className="section_padding"></div>
        <div className="container">
          <Row
            className="room_details"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Col
              xl={19}
              lg={19}
              md={19}
              sm={24}
              xs={24}
              className="Junior_Suite"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div>
                <div className="section_heading has-text-centered">
                  <h1 className="has-text-centered">
                    <span className="diff">R</span>oom Details
                  </h1>
                  <p className="is-size-6 has-text-centered pt-1 mt-0 sub_menu">
                    LUXURY HOTEL
                  </p>
                </div>
                {/* <div className="suite_content pt-5 mt-3">
                  <p className="has-text-justified has-text-grey-dark">
                    Hotel non lorem ac erat suscipit bibendum nulla facilisi.
                    Sedeuter nunc volutpat miss sapien vel conseyen
                    turpeutionyer masin libero sevenion vusetion viventa augue
                    sit amet hendrerit vestibulum. Duisteyerion venenatis lacus
                    gravida eros ut turpis interdum ornare.
                  </p>
                  <br />
                  <p className="has-text-justified has-text-grey-dark">
                    Interdum et malesu they adamale fames ac anteipsu pimsine
                    faucibus curabitur arcu site feugiat in tortor in, volutpat
                    sollicitudin libero. Hotel non lorem acer suscipit bibendum
                    vulla facilisi nedeuter nunc volutpa mollis sapien velet
                    conseyer turpeutionyer masin libero sempe mollis.
                  </p>
                </div> */}
                <div className="mt-5 pt-3">
                  <Row>
                    <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                      <div>
                        <p className="is-size-4 brown_color mt-3">Check-in</p>
                        <div className="mt-3">
                          <Space direction="horizontal">
                            <AiOutlineCheck className="brown_color" />
                            <p className="has-text-grey-dark">
                              Check-in from 12:00 AM
                            </p>
                          </Space>
                          <Space direction="horizontal">
                            <AiOutlineCheck className="brown_color" />
                            <p className="has-text-grey-dark">
                              Early check-in subject to availability
                            </p>
                          </Space>
                        </div>
                      </div>
                    </Col>
                    <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                      <div>
                        <p className="is-size-4 brown_color mt-3">Check-out</p>
                        <div className="mt-3">
                          <Space direction="horizontal">
                            <AiOutlineCheck className="brown_color" />
                            <p className="has-text-grey-dark">
                              Check-out before 12:00 AM
                            </p>
                          </Space>
                          {/* <Space direction="horizontal">
                            <AiOutlineCheck className="brown_color" />
                            <p className="has-text-grey-dark">
                              Early express check-out availability
                            </p>
                          </Space> */}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="suite_content pt-5 mt-3">
                  <p className="is-size-4 brown_color mb-3">
                    Special check-in instructions
                  </p>
                  <p className="has-text-justified has-text-grey-dark">
                    Guests will receive an email 5 days before arrival with
                    check-in instructions; front desk staff will greet guests on
                    arrival For more details, please contact the property using
                    the information on the booking confirmation.
                  </p>
                </div>
                <div className="suite_content pt-5 mt-3">
                  <p className="is-size-4 brown_color mb-3">Pets</p>
                  <p className="has-text-justified has-text-grey-dark">
                    Pets are allowed
                  </p>
                </div>
                {/* <div className="suite_content pt-5 mt-3">
                  <p className="is-size-4 brown_color mb-3">
                    Children and extra beds
                  </p>
                  <p className="has-text-justified has-text-grey-dark">
                    Children are welcome Kids stay free! Children stay free when
                    using existing bedding; children may not be eligible for
                    complimentary breakfast Rollaway/extra beds are available
                    for $ 10 per day.
                  </p>
                </div> */}
              </div>
            </Col>
            <Col
              xl={5}
              lg={5}
              md={5}
              sm={24}
              xs={24}
              className="pt-5 mt-5"
              // style={{ display: "flex", justifyContent: "center" }}
            >
              <div>
                <p className="is-size-4 brown_color mb-3 mt-5 has-text-weight-semibold">
                  Amenities
                </p>
                <div>
                  <p className="py-1 has-text-grey-dark">1-2 Persons</p>
                  <p className="py-1 has-text-grey-dark">Free Wifi</p>
                  <p className="py-1 has-text-grey-dark">200 sqft room</p>
                  <p className="py-1 has-text-grey-dark">Breakfast</p>
                  <p className="py-1 has-text-grey-dark">Towels</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="section_padding"></div>
      </section>
    </>
  );
};
export default Roomdetails;
