import { Col, Row, Space } from "antd";
import { MdFreeBreakfast } from "react-icons/md";
import { MdDinnerDining } from "react-icons/md";
import { MdLunchDining } from "react-icons/md";
import React from "react";

const Restaurant = () => {
  return (
    <>
      <section className="breadcrumbs breadcrumbs_padding">
        <div className="section_padding"></div>
        <div className="container has-text-centered">
          <div className="page-title">
            <h1 className="title has-text-white">Restaurant</h1>
          </div>

          <div className="page-breadcrumbs has-text-white mt-5">
            {/* <div>
              <p>
                <Link to={url.home} className="has-text-white breadcrumbs_home">
                  <Space direction="horizontal">
                    <div>Home</div>
                    <div style={{ lineHeight: "0" }}>
                      <MdOutlineArrowForwardIos />
                    </div>
                  </Space>
                </Link>
                <Link
                  to={url.Aboutus}
                  className="has-text-white breadcrumbs_home"
                >
                  <span>&nbsp;About Us</span>
                </Link>
              </p>
            </div> */}
          </div>
        </div>
        <div className="section_padding"></div>
      </section>

      <section className="mobile_margin back">
        <div className="section_padding"></div>
        <div className="container">
          <div>
            <p className="has-text-centered mb-5">
              Inspired by the erstwhile royal kitchens of India, this exclusive
              vegetarian restaurant offers a fine collection of timeless
              delicacies, based on the ancient Indian system of seasonal cooking
              & decades of culinary research.
            </p>
            <div className="section_heading">
              <h1 className="has-text-centered mb-4 pb-4 mt-5 pt-5">
                <span className="diff">F</span>ood Menu
              </h1>
            </div>
          </div>
          <div className="food_divider">
            <Row>
              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <Space>
                  <div style={{ lineHeight: "0" }}>
                    <MdFreeBreakfast className="is-size-4 brown_color" />
                  </div>
                  <p className="has-text-left is-size-4 has-text-weight-semibold brown_color">
                    Breakfast&nbsp;&nbsp;
                  </p>
                </Space>
              </Col>
              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <div className="has-text-right food_wrapper">
                  <img
                    src="Sources/images/food/2.png"
                    alt="404 - Not Givem"
                    className="food_img"
                  />
                </div>
                {/* <p className="has-text-right has-text-weight-semibold">
                  Nov - Dec 2021
                </p> */}
              </Col>
              <div className="line"></div>
            </Row>
            <div>
              <Row className="mt-4">
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                  <p className="has-text-left is-size-5 has-text-weight-semibold">
                    Lorem ipsum dolor
                  </p>
                  <p className="has-text-left is-size-6">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  </p>
                </Col>
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                  <p className="is-size-5 has-text-right has-text-weight-semibold">
                    150Rs
                  </p>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                  <p className="has-text-left is-size-5 has-text-weight-semibold">
                    Aliquam tincidunt
                  </p>
                  <p className="has-text-left is-size-6">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    Donec odio.
                  </p>
                </Col>
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                  <p className="is-size-5 has-text-right has-text-weight-semibold">
                    100Rs
                  </p>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                  <p className="has-text-left is-size-5 has-text-weight-semibold">
                    Vestibulum auctor
                  </p>
                  <p className="has-text-left is-size-6">
                    Lorem ipsum dolor sit amet, consectetuer.
                  </p>
                </Col>
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                  <p className="is-size-5 has-text-right has-text-weight-semibold">
                    190Rs
                  </p>
                </Col>
              </Row>
            </div>
          </div>
          <div className="food_divider">
            <Row>
              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <Space>
                  <div style={{ lineHeight: "0" }}>
                    <MdLunchDining className="is-size-4 brown_color" />
                  </div>
                  <p className="has-text-left is-size-4 has-text-weight-semibold brown_color">
                    Lunch&nbsp;&nbsp;
                  </p>
                </Space>
              </Col>
              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <div className="has-text-right food_wrapper">
                  <img
                    src="Sources/images/food/3.png"
                    alt="404 - Not Givem"
                    className="food_img"
                  />
                </div>
                {/* <p className="has-text-right has-text-weight-semibold">
                  Nov - Dec 2021
                </p> */}
              </Col>
              <div className="line"></div>
            </Row>
            <div>
              <Row className="mt-4">
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                  <p className="has-text-left is-size-5 has-text-weight-semibold">
                    Lorem ipsum dolor
                  </p>
                  <p className="has-text-left is-size-6">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  </p>
                </Col>
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                  <p className="is-size-5 has-text-right has-text-weight-semibold">
                    150Rs
                  </p>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                  <p className="has-text-left is-size-5 has-text-weight-semibold">
                    Aliquam tincidunt
                  </p>
                  <p className="has-text-left is-size-6">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    Donec odio.
                  </p>
                </Col>
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                  <p className="is-size-5 has-text-right has-text-weight-semibold">
                    100Rs
                  </p>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                  <p className="has-text-left is-size-5 has-text-weight-semibold">
                    Vestibulum auctor
                  </p>
                  <p className="has-text-left is-size-6">
                    Lorem ipsum dolor sit amet, consectetuer.
                  </p>
                </Col>
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                  <p className="is-size-5 has-text-right has-text-weight-semibold">
                    190Rs
                  </p>
                </Col>
              </Row>
            </div>
          </div>
          <div className="food_divider">
            <Row>
              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <Space>
                  <div style={{ lineHeight: "0" }}>
                    <MdDinnerDining className="is-size-4 brown_color" />
                  </div>
                  <p className="has-text-left is-size-4 has-text-weight-semibold brown_color">
                    Dinner &nbsp;&nbsp;
                  </p>
                </Space>
              </Col>
              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <div className="has-text-right food_wrapper">
                  <img
                    src="Sources/images/food/4.png"
                    alt="404 - Not Givem"
                    className="food_img"
                  />
                </div>
                {/* <p className="has-text-right has-text-weight-semibold">
                  Nov - Dec 2021
                </p> */}
              </Col>
              <div className="line"></div>
            </Row>
            <div>
              <Row className="mt-4">
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                  <p className="has-text-left is-size-5 has-text-weight-semibold">
                    Lorem ipsum dolor
                  </p>
                  <p className="has-text-left is-size-6">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  </p>
                </Col>
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                  <p className="is-size-5 has-text-right has-text-weight-semibold">
                    150Rs
                  </p>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                  <p className="has-text-left is-size-5 has-text-weight-semibold">
                    Aliquam tincidunt
                  </p>
                  <p className="has-text-left is-size-6">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    Donec odio.
                  </p>
                </Col>
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                  <p className="is-size-5 has-text-right has-text-weight-semibold">
                    100Rs
                  </p>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                  <p className="has-text-left is-size-5 has-text-weight-semibold">
                    Vestibulum auctor
                  </p>
                  <p className="has-text-left is-size-6">
                    Lorem ipsum dolor sit amet, consectetuer.
                  </p>
                </Col>
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                  <p className="is-size-5 has-text-right has-text-weight-semibold">
                    190Rs
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="section_padding"></div>
      </section>
    </>
  );
};

export default Restaurant;
