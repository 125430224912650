export const homecard = [
  {
    src: "Sources/images/about/5.png",
    alt: "404",
    para: "The family of Devgadh Baria originates from the Khichi Chauhan dynasty, descendants of Prithviraj Chauhan. Devgadh Baria town was established in 1524 by Maharawal Dungar Singh Ji grandson of Maharawal Patai Rawal, after escaping the conquest by Mohammad Begada in Pavagadh. Rest of the geneology can be followed on wikipedia.",
  },
  {
    src: "Sources/images/about/2.png",
    alt: "404",
    para: "On 13th March, 2013, the present royal family moved back into The Rajmahal. The palace was renovated in the span of 9 years being restored to its original glory.",
  },
  {
    src: "Sources/images/about/3.png",
    alt: "404",
    para: "Princess Urvashi Devi, former minister and MLA of the Gujarat State Government, has worked a lot for the rural development of the town and neighbouring areas of Devgadh Baria. She is an avid propagator for animal welfare and preservation of environment.",
  },
  {
    src: "Sources/images/about/4.png",
    alt: "404",
    para: "Maharaja Tushar Singh Ji, former MLA of the Gujarat State Government, is the 18th Maharawal of Devgadh Baria state. He is currently living at the palace with his wife Maharani Ambika Kumari Ji and two sons Yuvraj Arunoday Singh Ji and Maharaj Kumar Nirbhay Singh Ji.",
  },
  {
    src: "Sources/images/about/1.png",
    alt: "404",
    para: "The palace was established in 1904. It has four opulent suites, lush gardenswith open spaces and a lake. It also has a ball room, which was built during the visit of The Governor of Bombay Sir Frederick Hugh Sykes.",
  },
];
