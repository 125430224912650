import React from "react";
import "./policy.css";

const Policy = () => {
  return (
    <>
      <section className="Child_policy">
        <div className="section_padding"></div>
        <div className="container">
          <div className="section_heading">
            <h1 className="has-text-centered ">
              <span className="diff">C</span>hild Policy
            </h1>
          </div>
          <div className="mx-5 mt-5">
            <li>Extra Bed:- 6,000/- inclucive of all meals.</li>
            <br />
            <li>Child upto- 5 Years Complimentry</li>
            <br />
            <li>
              2 Kids upto 5 years , 1 child will be charged @ 3,000/- with extra
              bed and all meals per night.
            </li>
            <br />
            <li>
              Child 6 to 9years @ 3,000/- with extra bed and all meals per
              night.
            </li>
            <br />
            <li>
              Child 9 year and above will be considered as an adult. Extra bed @
              6,000/- per night.
            </li>
            <br />
          </div>
        </div>

        <div className="section_padding"></div>
      </section>
      {/* <section>
        <div className="move_img">
          <div className="section_padding"></div>
        </div>
        <div className="section_padding"></div>
      </section> */}
      <section className="cacellation_policy">
        <div className="section_padding"></div>
        <div className="container">
          <div className="section_heading">
            <h1 className="has-text-centered ">
              <span className="diff">C</span>ancellation Policy
            </h1>
          </div>
          <div className="mx-5 mt-5">
            <li>
              In case of cancellation due to State Lockdown or Curfew, the
              booking will be honored until the next 90 days (subject to
              availability.)
            </li>
            <br />
            <li>
              In case of cancellation due to COVID-19, a COVID positive
              certificate will be required and we will honor the booking until
              the next 90 days. (Subject to availability.)
            </li>
            <br />
            <li>
              Bookings cancelled within 15 days to check-in will not be
              refunded.
            </li>
            <br />
            <li>
              Bookings cancelled within 21 days to check-in will be 50%
              refundable.
            </li>
            <br />
            <li>
              Bookings cancelled within 30 days to check-in will be 75%
              refundable.
            </li>
            <br />
            <li>No Refund in case of a roadblock, accidents or a no show.</li>
            <br />
          </div>
        </div>

        <div className="section_padding"></div>
      </section>
    </>
  );
};

export default Policy;
