import { Button, Col, Form, Input, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { Link } from "react-router-dom";
import { url } from "../../constants/navhome";

const Inquiry = () => {
  const onFinish = (formData) => {
    console.log(formData);
  };
  return (
    <>
      <section>
        <div className="container">
          <div className="inquiry">
            <div className="section_heading">
              <h1 className="has-text-centered mb-5">
                <span className="diff">B</span>ook Now
              </h1>
            </div>
            <Form onFinish={onFinish}>
              {/* <Form.Item
                name="mobile"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Amount to Pay",
                    pattern: /^[0-9]{10}$/,
                  },
                ]}
              >
                <Input
                  placeholder="Enter Amount to Pay"
                  className="contact_input"
                />
              </Form.Item>
              <Form.Item
                name="pan number"
                rules={[
                  {
                    required: true,
                    message: "Please Enter your PAN number",
                    pattern: /^[0-9]{10}$/,
                  },
                ]}
              >
                <Input
                  placeholder="Please Enter your PAN number"
                  className="contact_input"
                />
              </Form.Item>
              <Form.Item
                name="pan number"
                rules={[
                  {
                    required: true,
                    message: "Please Enter your PAN number",
                    pattern: /^[0-9]{10}$/,
                  },
                ]}
              >
                <Input
                  placeholder="Please Enter your PAN number"
                  className="contact_input"
                />
              </Form.Item>
              <Form.Item
                name="Aadhar card number"
                rules={[
                  {
                    required: true,
                    message: "Please Enter your Aadhar card number",
                    pattern: /^[0-9]{10}$/,
                  },
                ]}
              >
                <Input
                  placeholder="Please Enter your Aadhar card PAN number"
                  className="contact_input"
                />
              </Form.Item> */}
              <Row>
                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  sm={24}
                  xs={24}
                  className="citystate_desk"
                >
                  <Form.Item
                    className="main_input"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter valid name",
                        pattern: /^[A-Za-z\s\b]+$/,
                      },
                    ]}
                  >
                    <Input placeholder="Name" className="contact_input" onc />
                  </Form.Item>
                </Col>
                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  sm={24}
                  xs={24}
                  className="citystate_desk"
                >
                  <Form.Item
                    className="main_input"
                    name="Surname"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Surname ",
                        pattern: /^[A-Za-z\s\b]+$/,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Surname"
                      className="contact_input"
                      onc
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  sm={24}
                  xs={24}
                  className="citystate_desk"
                >
                  <Form.Item
                    name="mobile"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter valid Mobile",
                        pattern: /^[0-9]{10}$/,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Your Mobile"
                      className="contact_input"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  sm={24}
                  xs={24}
                  className="citystate_desk"
                >
                  <Form.Item
                    name="Email"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter valid email",
                        pattern: /^[A-Za-z0-9._-]+[A-Za-z]+.[A-Za-z]+$/,
                      },
                    ]}
                  >
                    <Input placeholder="Your Email" className="contact_input" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  sm={24}
                  xs={24}
                  className="citystate_desk"
                >
                  <Form.Item
                    className="main_input"
                    name="Address"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Address",
                        pattern: /^[A-Za-z\s\b]+$/,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Address"
                      className="contact_input"
                      onc
                    />
                  </Form.Item>
                </Col>
                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  sm={24}
                  xs={24}
                  className="citystate_desk"
                >
                  <Form.Item
                    className="main_input"
                    name="Pincode"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Pin Code",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Pin Code"
                      className="contact_input"
                      onc
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col
                  xl={8}
                  lg={8}
                  md={8}
                  sm={24}
                  xs={24}
                  className="citystate_desk"
                >
                  <Form.Item
                    className="main_input"
                    name="City"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter City",
                        pattern: /^[A-Za-z\s\b]+$/,
                      },
                    ]}
                  >
                    <Input placeholder="City" className="contact_input" onc />
                  </Form.Item>
                </Col>
                <Col
                  xl={8}
                  lg={8}
                  md={8}
                  sm={24}
                  xs={24}
                  className="citystate_desk"
                >
                  <Form.Item
                    className="main_input"
                    name="State"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your State",
                      },
                    ]}
                  >
                    <Input placeholder="State" className="contact_input" onc />
                  </Form.Item>
                </Col>
                <Col
                  xl={8}
                  lg={8}
                  md={8}
                  sm={24}
                  xs={24}
                  className="citystate_desk"
                >
                  <Form.Item
                    className="main_input"
                    name="Country"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Country",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Country"
                      className="contact_input"
                      onc
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please Enter your Aadhar card number",
                    pattern: /^[A-Za-z\s\b]+$/,
                  },
                ]}
              >
                <TextArea placeholder="Message" />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  className="bgbrown_color get_in"
                  size="large"
                >
                  <Link to={url.Contactus}>Submit</Link>
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </section>
    </>
  );
};
export default Inquiry;
