export const galleryImages = [
  {
    rows: 1,
    cols: 2,
    src: "Sources/images/galery/ambica_landscape/1.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/2.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/3.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/4.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/5.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/22.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/52.png",
  },
  {
    rows: 1,
    cols: 2,
    src: "Sources/images/galery/ambica_landscape/2.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/56.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/8.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/54.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/10.png",
  },
  {
    rows: 1,
    cols: 2,
    src: "Sources/images/galery/ambica_landscape/3.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/11.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/12.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/54.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/13.png",
  },
  {
    rows: 1,
    cols: 2,
    src: "Sources/images/galery/ambica_landscape/4.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/14.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/15.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/16.png",
  },

  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/17.png",
  },
  {
    rows: 1,
    cols: 2,
    src: "Sources/images/galery/ambica_landscape/5.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/18.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/20.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/19.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/21.png",
  },
  {
    rows: 1,
    cols: 2,
    src: "Sources/images/galery/ambica_landscape/6.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/55.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/23.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/24.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/25.png",
  },
  {
    rows: 1,
    cols: 2,
    src: "Sources/images/galery/ambica_landscape/7.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/26.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/27.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/28.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/29.png",
  },
  {
    rows: 1,
    cols: 2,
    src: "Sources/images/galery/ambica_landscape/8.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/31.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/32.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/33.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/34.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/35.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/36.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/37.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/38.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/38.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/40.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/41.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/42.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/44.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/45.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/46.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/47.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/48.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/49.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/50.png",
  },
  {
    rows: 1,
    cols: 1,
    src: "Sources/images/Ambica_500500/51.png",
  },
];
