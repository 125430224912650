export const browseGallery = [
  {
    src: "Sources/images/Ambica_500500/1.png",
  },
  {
    src: "Sources/images/Ambica_500500/2.png",
  },
  {
    src: "Sources/images/Ambica_500500/3.png",
  },
  {
    src: "Sources/images/Ambica_500500/4.png",
  },
  {
    src: "Sources/images/Ambica_500500/5.png",
  },
  {
    src: "Sources/images/Ambica_500500/20.png",
  },
];
